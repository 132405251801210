.table-container {
  /* display: flex; */
  /* width: 103.7rem; */
  /* height: 36.5rem; */
  /* margin-top: 69.2rem; */
  /* margin-left: 37.9rem; */
  border: 0.1rem solid rgba(222, 222, 222, 1);
  border-radius: 0.8rem;
  align-items: center;
}
.table {
  border-collapse: collapse;
  display: flex;
  /* border: 1px solid rgba(222, 222, 222, 1) */
}

.tableHeading {
  border-bottom: 0.1rem solid rgba(222, 222, 222, 1);
  background-color: rgba(255, 255, 255, 1);
}
.tableHeading th {
  font-family: "Poppins", sans-serif;
  color: rgba(137, 137, 137, 1);
  width: 6.8rem;
  height: 1.4rem;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.4rem;
  letter-spacing: 1%;
}
.center {
  display: flex;
  justify-content: center;
}
#tableCell {
  font-family: "Poppins", sans-serif;
  width: 20rem;
  height: 2.4rem;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2.4rem;
  color: rgba(0, 0, 0, 1);
}
#tableCellTime {
  font-family: "Poppins", sans-serif;
  width: 10rem;
  height: 2.4rem;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2.4rem;
  color: rgba(0, 0, 0, 1);
}
#tableCellName {
  font-family: "Poppins", sans-serif;
  width: 10rem;
  height: 2.4rem;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2.4rem;
  color: rgba(0, 0, 0, 1);
}
.grayCell {
  background-color: rgba(248, 248, 248, 1);
  border-bottom: rgba(248, 248, 248, 1);
}
#tableCellTicketId {
  color: rgba(243, 110, 33, 1);
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 2.1rem;
  letter-spacing: 2%;
  /* background-color: rgba(255, 192, 203, 0.3); */
}

.image-container {
  min-width: 27.2rem;
  display: flex;
}

.image-cell {
  min-width: 27.2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.image {
  width: 13.6rem;
  height: 2.9rem;
}
/* @media(max-width:998px){
  html {
    font-size: 55%;
  }
} */

/* @media(max-width:768px){
  #table-container {
    width: 32.4rem;
    height: 109.1rem;
  }
  .table{
    width: 32.2rem;
    height: 26.3rem;
    border-radius: 10.0rem;
  }
} */

/* pagination */

.pagination__link--previous,
.pagination__link--next {
  color: rgba(243, 110, 33, 1);
  text-decoration: none;
  font-size: 1rem;
  font-weight: bold;
}

.pagination__link--break {
  color: rgba(243, 110, 33, 1);
  /* padding-left: 0.5rem; */
  /* padding-right: 0.5rem; */
}

.pagination__link--page {
  color: rgba(243, 110, 33, 1);
  font-weight: bold;
  font-size: 1.1rem;
  text-decoration: none;
  border: 0.1rem solid #ccc;
  padding: 0.8rem 1.6rem;
  border-radius: 0.4rem;
  /* margin-left: 0.5rem;
  margin-right: 0.5rem; */
}

.pagination__link--active {
  border: 0.2rem solid rgba(243, 110, 33, 1);
  border-radius: 0.4rem;
}

.pagination-section {
  display: flex;
  /* justify-content: space-between; */
  justify-content: center;
  cursor: pointer;
  align-items: center;
  margin-top: 2rem;
}

.pagination {
  display: flex;
  list-style: none;
  padding: 0;
}

.pagination__page {
  margin: 0 5rem;
}

.pagination__link {
  margin: 0 1rem;
  cursor: pointer;
  padding: 0.8rem 1.6rem;
  border-radius: 0.4rem;
  border: 0.1rem solid #ccc;
  background-color: #fff;
}

.pagination__link--active {
  border: 0.2rem solid rgba(243, 110, 33, 1);
  border-radius: 0.4rem;
}

.pagination-section {
  display: flex;
  /* justify-content: space-between; */
  justify-content: center;
  cursor: pointer;
  align-items: center;
  margin-top: 2rem;
}

.pagination__link--disabled {
  pointer-events: none;
  opacity: 0.5;
}

/* CreateTable.css */
.critical-tag {
  font-size: 0.7rem;
  color: #f41e1e;
  font-weight: bold;
  border: 0.1rem solid #ffeded;
  border-radius: 2rem;
  background: #ffeded;
  padding: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  flex-direction: row;
  display: inline-block; /* Ensure the text stays in one line */
  font-size: 1.2rem; /* Increase font size */
}

.impaired-tag {
  font-size: 0.7rem;
  color: #f4c006;
  font-weight: bold;
  border: 0.1rem solid #fff8e1;
  border-radius: 2rem;
  background: #fff8e1;
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: row;
  display: inline-block; /* Ensure the text stays in one line */
  font-size: 1.2rem;
}

.minor-tag {
  font-size: 0.7rem;
  color: #50bcf9;
  font-weight: bold;
  border: 0.1rem solid #e7f6ff;
  border-radius: 2rem;
  background: #e7f6ff;
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: row;
}

.Dispatch-tag {
  font-size: 1.2em;
  color: #9050f9;
  font-weight: bold;
  border: 0.1rem solid #f1e9ff;
  border-radius: 2rem;
  background: #f1e9ff;
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: row;
  margin-bottom: 1.5rem;
}
.Research-tag {
  font-size: 1.2rem;
  color: #5061f9;
  font-weight: bold;
  border: 0.1rem solid #e9ebff;
  border-radius: 2rem;
  background: #e9ebff;
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: row;
  margin-bottom: 1.5rem;
}

.table-cell-left {
  display: block; /* Change from flex to block to ensure proper text wrapping */
  padding-left: 1rem; /* Adjust padding as needed */
  max-width: 200px; /* Adjust the maximum width as needed */
  white-space: normal; /* Allow text to wrap to the next line */
  overflow-wrap: break-word; /* Ensure long words break to fit within the cell */
  line-height: 1.5; /* Adjust line height for spacing between lines */
  word-break: break-word; /* Break long words to avoid overflow */
}

@media (max-width: 768px) {
  .container1 {
    min-height: auto;
    /* min-height: 90.6rem; */
    max-height: none;
    width: 100%;
  }
}
