.main-container {
  margin-top: 1rem;
}

.container1 {
  width: 100%;
  /* min-height: 90.6rem; */
  background-color: rgba(248, 248, 248, 1);
  border: 0.2rem solid rgba(248, 248, 248, 1);
}
.section1 {
  /* width: 28.7rem; */
  /* height: 9.4rem; */
  gap: 1.8rem;
  padding-top: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  justify-content: center;
  align-items: center;
}
.text_alerts {
  width: 5.9rem;
  height: 3.2rem;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 2rem;
  line-height: 3.2rem;
  letter-spacing: 1%;
  color: #000000;
}
.btn {
  width: 11.5rem;
  height: 2.4rem;
  gap: 1rem;
  align-self: center;
  cursor: pointer;
}
.iconNseachbar {
  align-items: center;
}
.search_bar_new {
  border-color: white;
  box-shadow: 0 0.2rem 0.8rem 0 rgba(0, 0, 0, 0.2);
  border-radius: 0.8rem;
}

.search_bar_space {
  margin-left: 1rem;
}

.search_bar_new::placeholder {
  font-size: 1.4rem;
}
.search-icon {
  color: #000;
  align-items: center;
  align-self: center;
  padding-top: 1rem;
  margin-bottom: 1rem;
}
.suggestions {
  list-style-type: none;
  padding: 0;
  margin: 0.5rem 0;
  background-color: #f5f5f5;
  border: 0.1rem solid #ccc;
  border-radius: 0.5rem;
  box-shadow: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.1);
  font-size: 1.4rem;
  overflow-y: auto;
  height: 340px;
}

.suggestions li {
  padding: 0.8rem 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  color: black;
}

.suggestions li:hover {
  background-color: #fff;
}

.dropdown-icon {
  /* Other styles */
  transition: transform 0.3s ease;
}

.open {
  transform: rotate(180deg);
}

/* section 2 */

.text_all_alerts {
  color: #000;
  /* padding: 1.2rem; */
  font-size: 1.4rem;
}

.count_all_alerts {
  color: rgba(243, 110, 33, 1);
  font-size: 1.4rem;
  font-weight: 500;
}

.selected_alerts {
  margin-top: 2rem;
}

.alert_name {
  font-size: 1.4rem;
  color: black;
  font-weight: 400;
}

.id_n_count {
  display: flex;
  padding: 4px 0;
}
.alert_id {
  color: rgba(243, 110, 33, 1);
  font-size: 1.4rem;
  font-weight: bold;
  margin-left: 0;
  width: 5.4rem;
  height: 1.9rem;
  border: 0.1rem solid rgba(255, 192, 203, 0.3);
  border-radius: 0.8rem;
  background-color: rgba(255, 192, 203, 0.3);
  display: flex;
  justify-content: center;
}

.id_n_count_out {
  width: 100%;
}
.alert_id_count {
  width: 100%;
  color: rgba(243, 110, 33, 1);
  font-size: 1.4rem;
  font-weight: bold;
  text-align: end;
}

.selected {
  border: 1px solid rgba(243, 110, 33, 1);
}

/* pagination */

.pagination__link--previous,
.pagination__link--next {
  color: rgba(243, 110, 33, 1);
  text-decoration: none;
  font-size: 1rem;
  font-weight: bold;
}

.pagination__link--break {
  color: rgba(243, 110, 33, 1);
  /* padding-left: 0.5rem; */
  /* padding-right: 0.5rem; */
}

.pagination__link--page {
  color: rgba(243, 110, 33, 1);
  font-weight: bold;
  font-size: 1.1rem;
  text-decoration: none;
  border: 0.1rem solid #ccc;
  padding: 0.8rem 1.6rem;
  border-radius: 0.4rem;
  /* margin-left: 0.5rem;
  margin-right: 0.5rem; */
}

.pagination__link--active {
  border: 0.2rem solid rgba(243, 110, 33, 1);
  border-radius: 0.4rem;
}

.pagination-section {
  display: flex;
  /* justify-content: space-between; */
  justify-content: center;
  cursor: pointer;
  align-items: center;
  margin-top: 2rem;
}

.pagination {
  display: flex;
  list-style: none;
  padding: 0;
}

.pagination__page {
  margin: 0 5rem;
}

.pagination__link {
  margin: 0 1rem;
  cursor: pointer;
  padding: 0.8rem 1.6rem;
  border-radius: 0.4rem;
  border: 0.1rem solid #ccc;
  background-color: #fff;
}

.pagination__link--active {
  border: 0.2rem solid rgba(243, 110, 33, 1);
  border-radius: 0.4rem;
}

.pagination-section {
  display: flex;
  /* justify-content: space-between; */
  justify-content: center;
  cursor: pointer;
  align-items: center;
  margin-top: 2rem;
}

.pagination__link--disabled {
  pointer-events: none;
  opacity: 0.5;
}

@media (max-width: 768px) {
  .container1 {
    min-height: auto;
    /* min-height: 90.6rem; */
    max-height: none;
    width: 100%;
  }
}
