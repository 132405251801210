.chart-container {
  border: 0.1rem solid rgba(222, 222, 222, 1);
  border-radius: 6px;
  width: 100%;
  font-size: 12px;
}
.chart-wrapper{
  width: 100%;
  height: 580px;
  padding-bottom: 30px;
}

.key-card{
  height: 40px;
  padding-left: 20px;
  font-size: 20px;
  font-weight: 500;
}
text{
  font-size: 8px;
}

.center{
  display: flex;
  justify-content: center;
}
