.table-container {
  border: 0.1rem solid rgba(222, 222, 222, 1);
  border-radius: 0.8rem;
  align-items: center;
  margin-left: 40px;
  margin-top: 20px;
}
.table {
  border-collapse: collapse;
  display: flex;
  /* border: 1px solid rgba(222, 222, 222, 1) */
}

.filter-container {
  display: flex;
  margin-left: 40px;
}
.filterbox {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  margin-bottom: 12px;
}
.tableHeading {
  border-bottom: 0.1rem solid rgba(222, 222, 222, 1);
  background-color: rgba(255, 255, 255, 1);
}
.tableHeading th {
  font-family: "Poppins", sans-serif;
  color: rgba(137, 137, 137, 1);
  width: 2rem;
  height: 1.4rem;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.4rem;
  letter-spacing: 1%;
}
#tableCell {
  font-family: "Poppins", sans-serif;
  width: 26rem;
  height: 2.4rem;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2.4rem;
  color: rgba(0, 0, 0, 1);
}
#tableCellTime {
  font-family: "Poppins", sans-serif;
  width: 12rem;
  height: 2.4rem;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2.4rem;
  color: rgba(0, 0, 0, 1);
}
#tableCellName {
  font-family: "Poppins", sans-serif;
  width: 10rem;
  height: 2.4rem;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2.4rem;
  color: rgba(0, 0, 0, 1);
}
.grayCell {
  background-color: rgba(248, 248, 248, 1);
  border-bottom: rgba(248, 248, 248, 1);
}
#tableCellTicketId {
  color: rgba(243, 110, 33, 1);
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 2.1rem;
  letter-spacing: 2%;
  /* background-color: rgba(255, 192, 203, 0.3); */
}

.image-container {
  min-width: 27.2rem;
  display: flex;
}

.image-cell {
  min-width: 27.2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.image {
  width: 13.6rem;
  height: 2.9rem;
}
/* @media(max-width:998px){
    html {
      font-size: 55%;
    }
  } */

/* @media(max-width:768px){
    #table-container {
      width: 32.4rem;
      height: 109.1rem;
    }
    .table{
      width: 32.2rem;
      height: 26.3rem;
      border-radius: 10.0rem;
    }
  } */

/* pagination */
.pagination__link--previous,
.pagination__link--next {
  color: rgba(243, 110, 33, 1);
  text-decoration: none;
  font-size: 1rem;
  font-weight: bold;
}

.pagination__link--break {
  color: rgba(243, 110, 33, 1);
  /* padding-left: 0.5rem; */
  /* padding-right: 0.5rem; */
}

.pagination__link--page {
  color: rgba(243, 110, 33, 1);
  font-weight: bold;
  font-size: 1.1rem;
  text-decoration: none;
  border: 0.1rem solid #ccc;
  padding: 0.8rem 1.6rem;
  border-radius: 0.4rem;
  /* margin-left: 0.5rem;
  margin-right: 0.5rem; */
}

.pagination__link--active {
  border: 0.2rem solid rgba(243, 110, 33, 1);
  border-radius: 0.4rem;
}

.pagination-section {
  display: flex;
  /* justify-content: space-between; */
  justify-content: center;
  cursor: pointer;
  align-items: center;
  margin-top: 2rem;
}

.pagination {
  display: flex;
  list-style: none;
  padding: 0;
}

.pagination__page {
  margin: 0 5rem;
}

.pagination__link {
  margin: 0 1rem;
  cursor: pointer;
  padding: 0.8rem 1.6rem;
  border-radius: 0.4rem;
  border: 0.1rem solid #ccc;
  background-color: #fff;
}

.pagination__link--active {
  border: 0.2rem solid rgba(243, 110, 33, 1);
  border-radius: 0.4rem;
}

.pagination-section {
  display: flex;
  /* justify-content: space-between; */
  justify-content: center;
  cursor: pointer;
  align-items: center;
  margin-top: 2rem;
}

.pagination__link--disabled {
  pointer-events: none;
  opacity: 0.5;
}

@media (max-width: 768px) {
  .container1 {
    min-height: auto;
    /* min-height: 90.6rem; */
    max-height: none;
    width: 100%;
  }
}
