.loading-circle {
    border: 3px solid rgba(0, 0, 0, 0.1);
    border-top: 3px solid rgba(243, 110, 33, 1);
    border-radius: 50%;
    width: 18px;
    height: 18px;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  