.box {
  margin-right: 10px;
  margin-left: 10px;
  display: flex;
  flex-direction: row;
}
.chartTable_container {
  height:460px;
  margin-right: 18px;
  margin-left: 18px;
}

