.content_wrapper {
  width: 100%;
  box-sizing: border-box;
}
.main_container {
  margin-left: 8rem;
  margin-right: 8rem;
  margin-top: 4rem;
  margin-bottom: 4rem;
  font-family: "Poppins", sans-serif;
}
.text_createAltert {
  color: rgba(0, 0, 0, 1);
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  line-height: 4.8rem;
  font-size: 3.2rem;
  letter-spacing: 1%;
  display: flex;
  justify-content: flex-start;
}
.text_2_container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.text_2_prefix {
  color: rgba(79, 79, 79, 1);
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2.2rem;
}
.text_2_suffix {
  color: rgba(79, 79, 79, 1);
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2.2rem;
}
.form_container {
  margin-top: 4rem;
}
.custom_select {
  width: 49%;
}
.custom_select_sub {
  width: 49%;
}
.sub_container {
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
@media (max-width: 768px) {
  .content_wrapper {
    width: 100%;
    border-radius: 0.8rem;
  }
  .main_container {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  text {
    font-size: 2.4rem;
    font-weight: 500;
    line-height: 3.2rem;
    letter-spacing: 1%;
  }
  .text_2_container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .custom_select {
    width: 100%;
  }
  .custom_select_sub {
    width: 100%;
  }
  .space_container {
    margin-top: 2rem;
  }
  .sub_container {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
  }
}
